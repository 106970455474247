<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-splitter
          v-model="splitterModel"
          :limits="[30, 70]"
          unit="%"
        >
          <template v-slot:before>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-table
                ref="grpTable"
                title="Stepper 그룹"
                tableId="grpTable"
                :columnSetting="false"
                :isFullScreen="false"
                :usePaging="false"
                :filtering="true"
                :columns="grpGrid.columns"
                :data="grpGrid.data"
                @rowClick="getMsts"
                :isExcelDown="false"
              >
              </c-table>
            </div>
          </template>
          <template v-slot:after>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-table
                ref="mstTable"
                title="Stepper 마스터"
                tableId="mstTable"
                :columnSetting="false"
                :isFullScreen="false"
                :usePaging="false"
                :filtering="false"
                :columns="mstGrid.columns"
                :data="mstGrid.data"
                selection="multiple"
                rowKey="stepperMstCd"
                :isExcelDown="false"
                :editable="editable"
              >
                <!-- 버튼 영역 -->
                <template slot="table-button">
                  <c-btn label="저장" icon="save" @btnClicked="saveMst" v-if="editable && isSelectGrp" />
                </template>
              </c-table>
            </div>
          </template>
        </q-splitter>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'stepper-manage',
  data() {
    return {
      splitterModel: 30,
      searchParam: {
        useFlag: '',
      },
      editable: true,
      isSelectGrp: false,
      selectedGrpCd: '',
      grplistUrl: '',
      mstlistUrl: '',
      mstSaveUrl: '',
      grpGrid: {
        columns: [
          {
            name: 'stepperGrpCd',
            field: 'stepperGrpCd',
            label: '그룹코드',
            align: 'left',
            style: 'cursor:pointer;width:150px',
            sortable: false,
            required: true,
          },
          {
            name: 'stepperGrpNm',
            field: 'stepperGrpNm',
            label: '그룹명',
            align: 'left',
            sortable: false,
            required: true,
          },
        ],
        data: [],
      },
      mstGrid: {
        columns: [
          {
            name: 'stepperMstCd',
            field: 'stepperMstCd',
            label: '코드',
            align: 'center',
            style: 'width:100px',
            required: true,
            sortable: false,
          },
          {
            name: 'stepperMstNmKr',
            field: 'stepperMstNmKr',
            label: '타이틀',
            align: 'left',
            style: 'width:150px',
            type: 'text',
            required: true,
            sortable: false,
          },
          {
            name: 'stepperMstNmEn',
            field: 'stepperMstNmEn',
            label: '타이틀-영어',
            align: 'left',
            style: 'width:150px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'stepperMstNmJa',
            field: 'stepperMstNmJa',
            label: '타이틀-일본어',
            align: 'left',
            style: 'width:150px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'stepperMstNmZhCn',
            field: 'stepperMstNmZhCn',
            label: '타이틀-중국어간체',
            align: 'left',
            style: 'width:150px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'stepperMstNmZhTw',
            field: 'stepperMstNmZhTw',
            label: '타이틀-중국어번체',
            align: 'left',
            style: 'width:150px',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = (this.$store.getters.user.userId === 'U000000001' ? this.$route.meta.editable : false);
      this.grplistUrl = selectConfig.sys.stepper.grp.list.url;
      this.mstlistUrl = selectConfig.sys.stepper.mst.list.url;
      this.mstSaveUrl = transactionConfig.sys.stepper.mst.lang.url;
      this.getGrps();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
    },
    getGrps() {
      this.$http.url = this.grplistUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grpGrid.data = _result.data;
      },);
    },
    getMsts(row) {
      if (row.editFlag != 'C') {
        this.isSelectGrp = true;
        this.selectedGrpCd = row.stepperGrpCd;
        this.$http.url = this.$format(this.mstlistUrl, this.selectedGrpCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.mstGrid.data = _result.data;
        },
        () => {
        });
      }
    },
    saveMst() {
      if (this.$comm.validTable(this.mstGrid.columns, this.mstGrid.data)) {
        let saveData = this.mstGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.mstSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getMsts({stepperGrpCd: this.selectedGrpCd});
                window.getApp.$emit('ALERT', {
                  title: '안내 ', 
                  message: '저장되었습니다.', 
                  type: 'success', // success / info / warning / error
                });
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
  }
};
</script>
